import { fabric } from "fabric";
import CanvasHistory from "./canvasHistory.js";

export default (function () {
    let activeObject = false;
    let drag, textColor, textFontFamily, textFontSize, customText, color, textFontStyle, textFontWeight, textId;
    let isMobile = false;
    let mobileTextHandler = () => { };

    function Text(canvas, draggable = false, params) {
        this.canvas = canvas;
        this.isDrawing = false;
        this.origX = 0;
        this.origY = 0;
        this.bindEvents();
        drag = draggable;

        if (params) {
            textColor = params.fill;
            textFontFamily = params.fontFamily;
            textFontSize = params.fontSize;
            textFontStyle = params.fontStyle;
            textFontWeight = params.fontWeight;
            customText = params.placeholder;
            isMobile = params.isMobile
            textId = params.id;
            mobileTextHandler = params.mobileTextHandler;
            if (canvas.getActiveObject() && canvas.getActiveObject().hasOwnProperty('text')) {
                canvas.getActiveObject().set({
                    fill: textColor,
                    stroke: textColor,
                    fontFamily: textFontFamily,
                    fontSize: textFontSize,
                    fontStyle: textFontStyle,
                    fontWeight: textFontWeight,
                    id: textId
                });
                canvas.renderAll();
            }
        }
    }

    Text.prototype.bindEvents = function () {
        let inst = this;
        inst.selectable = true;
        inst.canvas.off("mouse:down");
        inst.canvas.on("mouse:down", function (o) {
            inst.onMouseDown(o);
        });

        inst.canvas.off("mouse:move");
        inst.canvas.on("mouse:move", function (o) {
            inst.onMouseMove(o);

        });

        inst.canvas.off("mouse:up");
        inst.canvas.on("mouse:up", function (o) {
            inst.onMouseUp(o);
        });

        inst.canvas.off("text:editing:entered");
        inst.canvas.on("text:editing:entered", function (o) {
            if (mobileTextHandler && o.target) {
                mobileTextHandler(o.target);
            } else {
                o.target.hasControls = true;
                o.target.hasBorders = true;
            }
        })
        
        inst.canvas.off("object:modified");
        inst.canvas.on("object:modified", function(o) {
            if (inst.canvas.getActiveObject() && activeObject && inst.canvas.getActiveObject().hiddenTextarea) {
                new CanvasHistory(inst.canvas.toJSON());
            }
        })

        Text.prototype.onMouseUp = function () {
            return Text;
        };
        Text.prototype.onMouseMove = function () {
            let inst = this;
            if (!inst.isEnable()) {
                return;
            }
            activeObject = inst.canvas.getActiveObject();
            inst.canvas.renderAll();
        };
        Text.prototype.onMouseDown = function (o) {
            let inst = this;

            if (drag) {
                inst.enable();
                if (inst.canvas.getActiveObject() && !inst.canvas.getActiveObject().text) {
                    inst.canvas.getActiveObject().selectable = false;
                    inst.canvas.getActiveObject().evented = false;
                }
                if ((!inst.canvas.getActiveObject() && !activeObject) || (inst.canvas.getActiveObject() && !inst.canvas.getActiveObject().text)) {
                    let pointer = inst.canvas.getPointer(o.e);
                    this.origX = pointer.x;
                    this.origY = pointer.y;
                    let text = new fabric.IText(customText, {
                        fill: textColor,
                        stroke: textColor,
                        fontFamily: textFontFamily,
                        left: this.origX,
                        top: this.origY,
                        fontSize: textFontSize,
                        fontStyle: textFontStyle ? textFontStyle : '',
                        fontWeight: textFontWeight,
                        padding: 6,
                        hasBorders: true,
                        hasControls: true,
                        id: textId,
                    });
                    
                    inst.canvas.add(text);
                    inst.canvas.setActiveObject(text);

                    if (!isMobile) {
                        text.selectionStart = 0;
                        text.selectionEnd = text.text.length;
                        text.enterEditing();
                        text.hiddenTextarea.focus();
                        text.hasControls = true;
                    } else if (mobileTextHandler) {
                        mobileTextHandler(text);
                    }
                    inst.canvas.requestRenderAll();
                    new CanvasHistory(inst.canvas.toJSON());
                }
                if (inst.canvas.getActiveObject() && activeObject && inst.canvas.getActiveObject().hiddenTextarea) {
                    inst.canvas.getActiveObject().hasControls = true;
                    inst.canvas.getActiveObject().hasBorders = true;
                    inst.canvas.getActiveObject().lockMovementX = true;
                    inst.canvas.getActiveObject().lockMovementY = true;
                    inst.canvas.getActiveObject().lockUniScaling = true;
                    inst.canvas.renderAll();
                }
                if (!inst.canvas.getActiveObject()) {
                    activeObject = false;
                }
            } else {
                console.log("NOT DOWN");
            }
        };
    };
    Text.prototype.isEnable = function () {
        return this.isDrawing;
    };
    Text.prototype.enable = function () {
        this.isDrawing = true;
    };
    Text.prototype.disable = function () {
        this.isDrawing = false;
    };
    return Text;
}());
