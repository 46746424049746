import { fabric } from "fabric";
import CanvasHistory from "./canvasHistory";

export default (function () {
  let active = false;
  let curve = null;
  let handlers = [];
  let removedPaths = [];
  //Default params:
  let properties;

  function Curve(canvas, enabled = false, undo = false, params) {
    active = enabled;
    this.canvas = canvas;

    if (!active) {
      this.apply();
      this.canvas.forEachObject(object => {
        object.selectable = true;
        object.evented = true;
      });
    } else {
      properties = params;
      if (!undo) {
        curve = null;
        handlers = [];
        removedPaths = [];
      }
      this.bindEvents();
      this.updateCurve();
      if (!undo) {
        this.canvas.forEachObject(object => {
          object.selectable = false;
          object.evented = true;
        });
      }
    }
  }

  Curve.prototype.addJoint = function (e) {
    if (curve === null) {
      const pointer = this.canvas.getPointer(e.e);
      curve = new fabric.Path(
        `M ${pointer.x - 25} ${pointer.y - 25} Q ${pointer.x} ${pointer.y}, ${pointer.x + 25} ${pointer.y + 25}`,
        {
          ...properties,
          objectCaching: false,
          originX: 'center',
          originY: 'center',
          hasControls: false,
          hasBorders: false,
        });

      curve.selectable = false;
      this.canvas.add(curve);
      this.restoreHandlers();
    } else {
      const pointer = this.canvas.getPointer(e.e);
      const lastHandler = handlers.slice(-1)[0];
      const midX = lastHandler.left + (pointer.x - lastHandler.left) * 0.50;
      const midY = lastHandler.top + (pointer.y - lastHandler.top) * 0.50;
      const path = `${curve.path} Q ${midX} ${midY}, ${pointer.x} ${pointer.y}`;
      this.updateCurve(path);
    }
  }

  Curve.prototype.restoreHandlers = function () {
    if (!curve) {
      console.log("NO LINE!");
      return;
    }

    for (let index = 0; index < curve.path.length; index++) {
      const section = curve.path[index];

      if (index >= 0 && Math.abs(index * 2 - 1) < handlers.length) {
        continue
      }

      if (section[0] === "M") {
        handlers.push(this.addHandler(section[1], section[2], index, 1, 2));
      } else {
        handlers.push(this.addHandler(section[1], section[2], index, 1, 2, true));
        handlers.push(this.addHandler(section[3], section[4], index, 3, 4));
      }
    }
  }

  Curve.prototype.addHandler = function (x, y, i, j, k, arc) {
    var c = new fabric.Circle({
      left: x,
      top: y,
      strokeWidth: 3,
      radius: 6,
      fill: 'white',
      stroke: '#1BAAD6',
      originX: 'center',
      originY: 'center',
      opacity: 0.5,
      name: "handler",
    });

    c.hasBorders = c.hasControls = false;

    c.i = i;
    c.j = j;
    c.k = k;

    this.canvas.add(c);
    return c;
  }

  Curve.prototype.bindEvents = function () {
    let inst = this;

    inst.canvas.off("mouse:down");
    inst.canvas.on("mouse:down", function (o) {
      inst.onMouseDown(o);
    });

    inst.canvas.off("object:moving");
    inst.canvas.on("object:moving", function (o) {
      inst.onObjectMoving(o);
    });

    Curve.prototype.onObjectMoving = function (e) {
      if (active && handlers.indexOf(e.target) !== -1) {
        var p = e.target;
        curve.path[p.i][p.j] = p.left;
        curve.path[p.i][p.k] = p.top;
      }
    }

    Curve.prototype.onMouseDown = function (e) {
      if (active && handlers.indexOf(e.target) === -1) {
        this.addJoint(e);
        //Reset removed paths, if new path has been added.
        //It's because on position where last removed
        //path was new path has been added above.
        removedPaths = [];
      } else if (!active && this.canvas.getActiveObject()) {
        this.canvas.getActiveObject().hasControls = true;
        this.canvas.getActiveObject().hasBorders = true;
        this.canvas.getActiveObject().lockMovementX = false;
        this.canvas.getActiveObject().lockMovementY = false;
        this.canvas.getActiveObject().lockUniScaling = false;
        this.canvas.renderAll();
      }
    }
  };

  Curve.prototype.apply = function () {
    if (curve) {
      let inst = this;
      handlers.forEach(handler => inst.canvas.remove(handler));
      handlers = [];
      const selectableCurve = new fabric.Path(
        curve.path, {
        ...properties,
        objectCaching: false,
        originX: 'center',
        originY: 'center',
      });
      inst.canvas.remove(curve);
      inst.canvas.add(selectableCurve);
      curve = null;
      new CanvasHistory(inst.canvas.toJSON());
    }
  }

  Curve.prototype.removeJoint = function () {
    if (active && curve) {
      let inst = this;
      if (handlers.length > 3) {
        let lastHandlers = handlers.splice(-2);
        lastHandlers.forEach(handler => inst.canvas.remove(handler));
        removedPaths.push(curve.path.slice(-1));
        const path = curve.path.slice(0, -1);
        this.updateCurve(path);
      } else {
        handlers.forEach(handler => inst.canvas.remove(handler));
        handlers = [];
        inst.canvas.remove(curve);
        removedPaths = [];
        curve = null;
      }
      return true;
    }
    return false;
  }

  Curve.prototype.restoreJoint = function () {
    if (active && curve) {
      if (removedPaths.length > 0) {
        const removedPath = removedPaths.pop();
        const path = [...curve.path, removedPath];
        this.updateCurve(path);
      }
      return true;
    }
    return false;
  };

  Curve.prototype.updateCurve = function (path = false) {
    if (curve && path === false) {
      path = curve.path;
    } else if (!curve) {
      return;
    }

    let updatedPath = new fabric.Path(
      `${path}`, {
      ...properties,
      objectCaching: false,
      originX: 'center',
      originY: 'center',
    });

    curve.set({
      path: updatedPath.path,
      width: updatedPath.width,
      height: updatedPath.height,
      stroke: properties.stroke,
      strokeWidth: properties.strokeWidth,
      strokeDashArray: properties.strokeDashArray,
      hasControls: false,
      hasBorders: false,
    });
    this.restoreHandlers();
    this.canvas.requestRenderAll();
  }

  return Curve;
}());
