<template>
  <ConfirmDialog
    :value.sync="show"
    :title="title"
    positiveButtonText="Zapisz"
    :positiveAction="save"
    maxWidth="1220px"
    :fullscreen="fullscreen"
    noPadding
    dontHideOnPositiveAction
    persistent
    :retainFocus="false"
    showCloseBtn
  >
    <v-layout column align-center slot="content">
      <Editor ref="editor" :mode="mode"></Editor>
    </v-layout>
    <v-layout column slot="footer">
      <v-layout>
        <MoreMenuButton :groups="options" v-if="edit"></MoreMenuButton>
        <v-spacer></v-spacer>
        <DefaultButton @click.stop="hide" class="my-0"
          >Anuluj</DefaultButton
        >
        <DefaultButton
          @click.stop="save"
          :loading="loading"
          class="my-0"
          primary
          >Zapisz</DefaultButton
        >
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import ConfirmDialog from "@/components/popups/ConfirmDialog";
import Editor from "@/components/editors/Editor";

export default {
  props: {
    value: {},
    event: {
      type: Function,
    },
    data: {},
    edit: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Ilustracja dolegliwości",
    },
  },
  data() {
    return {
      options: [
        {
          items: [
            {
              title: "Pobierz",
              action: () => {
                this.$refs.editor.download();
              },
            },
            {
              title: "Drukuj",
              action: () => {
                this.$refs.editor.print();
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    fullscreen() {
      const height = this.$vuetify.breakpoint.height;
      const footer = 68;
      const header = this.$vuetify.breakpoint.smAndDown ? 264 : 184;
      return (
        this.$vuetify.breakpoint.smAndDown && height - (footer + header) <= 600
      );
    },
  },
  methods: {
    save() {
      this.$emit("save", this.$refs.editor.save());
      this.show = false;
    },
    hide() {
      this.show = false;
    },
  },
  components: {
    Editor,
    ConfirmDialog,
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    MoreMenuButton: () => import("@/components/menus/MoreMenuButton"),
  },
  mounted() {
    if (this.data && this.$refs && this.$refs.editor) {
      if (!this.edit) {
        this.$refs.editor.setBackgroundImage(this.data);
      } else {
        this.$refs.editor.restore(this.data);
      }
    }
  },
};
</script>