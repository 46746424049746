var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","max-width":"90","disabled":_vm.readonly,"content-class":"zg-select-border-radius"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-layout',{class:_vm.horizontal ? 'align-center' : 'column'},[(_vm.label)?_c('div',{class:[
            'text-subtitle-1 black--text',
            _vm.horizontal ? 'mt-1 ml-4' : 'mb-1 ml-4' ]},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-subtitle-1 primary--text"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('DefaultButton',_vm._g({class:['select', _vm.readonly ? 'avoid-clicks' : ''],staticStyle:{"min-width":"90px"},attrs:{"color":_vm.readonly ? 'grey' : 'black',"small":""}},on),[_c('v-layout',{attrs:{"justify-space-between":"","mx-1":""}},[_c('div',{style:({
                backgroundColor: _vm.selected,
                width: '20px',
                height: '20px',
              })}),_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.visible ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.colors),function(item,index){return _c('v-list-item',{key:index},[_c('v-layout',{attrs:{"justify-space-between":""}},[_vm._l((item),function(color){return [_c('v-hover',{key:color,scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return _c('div',{class:hover ? 'elevation-4' : 'elevation-0',style:({
                  backgroundColor: color,
                  width: '20px',
                  height: '20px',
                }),on:{"click":function($event){$event.preventDefault();return _vm.selectColor(color)}}})}}],null,true)})]})],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }