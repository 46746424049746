<template>
  <div style="width: 100%">
    <v-layout
      column
      full-width
      :style="{
        maxHeight: `${workspaceWrapperHeight}px !important`,
        position: 'relative',
      }"
    >
      <UroEditorToolbar
        v-if="mode == 'uro'"
        @undo="undo"
        @redo="redo"
        @clear="onClear"
      ></UroEditorToolbar>
      <DefaultEditorToolbar
        v-else
        @undo="undo"
        @redo="redo"
        @clear="onClear"
      ></DefaultEditorToolbar>
      <v-layout id="editor-wrapper" style="overflow: auto !important">
        <v-layout column style="height: 600px">
          <Workspace
            ref="editor"
            class="editor"
            :canvasHeight="workspaceHeight"
            :canvasWidth="workspaceWidth"
          ></Workspace>
          <ScrollPad
            v-if="$vuetify.breakpoint.mdAndDown"
            class="pad"
            @down="scrollDown"
            @up="scrollUp"
            @left="scrollLeft"
            @right="scrollRight"
          ></ScrollPad>
        </v-layout>
      </v-layout>
    </v-layout>
    <ConfirmDialog
      v-if="showClearDialog"
      negativeButtonText="Anuluj"
      title="Wyczyść"
      text="Czy na pewno chcesz wszystko usunąć?"
      :positiveAction="clear"
      :value.sync="showClearDialog"
    ></ConfirmDialog>
  </div>
</template>

<script>
import Workspace from "@/components/editors/Workspace";
import ColorSelect from "@/components/pickers/color/ColorSelect";

export default {
  props: {
    mode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colorValue: 0,
      showClearDialog: false,
      windowHeight: 0,
    };
  },
  computed: {
    workspaceHeight() {
      return 600;
    },
    workspaceWidth() {
      return 1200;
    },
    workspaceWrapperHeight() {
      const height = this.$vuetify.breakpoint.height;
      const base = this.$vuetify.breakpoint.mdAndDown ? height : height * 0.9;
      const footer = 68;
      const header = 64;
      const margins = this.$vuetify.breakpoint.mdAndDown ? 2 : 48;
      return base - footer - margins - header;
    },
  },
  components: {
    Workspace,
    ColorSelect,
    ToolButton: () => import("@/components/editors/ToolButton"),
    MenuToolButton: () => import("./MenuToolButton"),
    LineSizeSlider: () => import("@/components/editors/LineSizeSlider"),
    TextSizeSlider: () => import("@/components/editors/TextSizeSlider"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    ScrollPad: () => import("@/components/editors/ScrollPad"),
    DefaultEditorToolbar: () =>
      import("@/components/editors/toolbars/DefaultEditorToolbar"),
    UroEditorToolbar: () =>
      import("@/components/editors/toolbars/UroEditorToolbar"),
  },
  methods: {
    save() {
      return this.$refs.editor.save();
    },
    scrollDown() {
      document.getElementById("editor-wrapper").scrollTop += 20;
    },
    scrollUp() {
      document.getElementById("editor-wrapper").scrollTop -= 20;
    },
    scrollRight() {
      document.getElementById("editor-wrapper").scrollLeft += 20;
    },
    scrollLeft() {
      document.getElementById("editor-wrapper").scrollLeft -= 20;
    },
    clear() {
      this.$refs.editor.clear();
    },
    setBackgroundImage(data) {
      this.$refs.editor.setBackgroundImage(data);
    },
    restore(data) {
      this.$refs.editor.restore(data);
    },
    download() {
      this.$refs.editor.download();
    },
    print() {
      this.$refs.editor.print();
    },
    undo() {
      this.$refs.editor.undo();
    },
    redo() {
      this.$refs.editor.redo();
    },
    onClear() {
      this.showClearDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.helperbar-style {
  background-color: #f6f7fb !important;
  box-shadow: 0px -5px 8px -5px rgba(0, 0, 0, 0.1) !important;
}
.editor {
  min-height: 600px !important;
  min-width: 1200px !important;
  width: 1200px !important;
}
.pad {
  position: absolute;
  z-index: 2;
  bottom: 68px;
  right: 0;
}
.ewrapper {
  overflow: auto !important;
}
</style>