import { fabric } from 'fabric';
import CanvasHistory from "./canvasHistory";

let strokeWidth;
let scaleFactor;
let distanceFactor;
let lastPoint;
let brush;
fabric.ScarBrush = fabric.util.createClass(fabric.BaseBrush, {
    /**
     * Constructor
     * @param {fabric.Canvas} canvas
     * @return {fabric.RectBrush} Instance of a circle brush
     */
    initialize: function (canvas, params) {
        this.canvas = canvas;
        this.image = params.image;
        lastPoint = null;
        brush = null;
        distanceFactor = params.distanceFactor ? params.distanceFactor : 1.5;
        scaleFactor = params.scaleFactor ? params.scaleFactor : 2;
        strokeWidth = params.strokeWidth;
    },

    _render: function () { },

    _reset: function () {
        lastPoint = null;
        brush = null;
    },

    /**
     * Invoked on mouse down
     * @param {Object} pointer
     */
    onMouseDown: function (pointer) {
        this.addElement(pointer);
    },

    /**
     * Invoked on mouse move
     * @param {Object} pointer
     */
    onMouseMove: function (pointer) {
        this.addElement(pointer);
    },

    /**
     * Invoked on mouse up
     */
    onMouseUp: function () {
        this._reset();
    },

    addElement: function (point) {
        let canvas = this.canvas;

        if (lastPoint && lastPoint.distanceFrom(point) < (strokeWidth * scaleFactor * distanceFactor)) {
            return;
        }

        lastPoint = point;

        if (brush) {
            brush.clone(function(clonedObj) {
                const size = strokeWidth * scaleFactor;

                clonedObj.set({
                    left: point.x - (size / 2),
                    top: point.y - (size / 2),
                });
                clonedObj.scaleToWidth(size);
                clonedObj.scaleToHeight(size);
    
                canvas.add(clonedObj);
                canvas.renderAll();
            });
        } else {
            fabric.loadSVGFromURL(this.image, function (objects, options) {
                brush = fabric.util.groupSVGElements(objects, options);

                const size = strokeWidth * scaleFactor;

                brush.set({
                    left: point.x - (size / 2),
                    top: point.y - (size / 2),
                });
                brush.scaleToWidth(size);
                brush.scaleToHeight(size);

                canvas.add(brush);
                canvas.renderAll();
            });
        }
    }
});

export default (function () {
    function ScarBrush(canvas, image) {
        return new fabric.ScarBrush(canvas, image);
    }
    return ScarBrush;
}());