<template>
  <div class="custom-editor">
    <canvas :id="editorId"></canvas>
    <MobileTextHandlerPopup
      v-if="visibleMobileTextHandler"
      :value.sync="visibleMobileTextHandler"
      :text.sync="mobileText"
      @save="saveMobileText"
      @cancel="cancelMobileText"
    ></MobileTextHandlerPopup>
  </div>
</template>

<script>
import printJS from "print-js";
import { fabric } from "fabric";
import Shape from "./tools/js/shape";
import Text from "./tools/js/text";
import Arrow from "./tools/js/arrow";
import CanvasHistory from "./tools/js/canvasHistory";
import Curve from "./tools/js/curve";
import ScarBrush from "./tools/js/scarBrush";
import ToolsMixin from "@/mixins/editors/ToolsMixin";

export default {
  mixins: [ToolsMixin],
  props: {
    canvasWidth: {
      type: [String, Number],
      required: true,
    },
    canvasHeight: {
      type: [String, Number],
      required: true,
    },
    editorId: {
      type: String,
      default: "c",
      required: false,
    },
  },
  data() {
    return {
      canvas: null,
      pointerX: null,
      pointerY: null,
      createShape: false,
      createCurve: false,
      createText: false,
      createArrow: false,
      objects: [],
      params: {},
      history: [],
      visibleMobileTextHandler: false,
      mobileText: "",
      textObject: null,
      backgroundImage: null,
      restoring: false,
    };
  },
  components: {
    MobileTextHandlerPopup: () =>
      import("@/components/editors/MobileTextHandlerPopup"),
  },
  created() {
    new CanvasHistory(null, true);
  },
  mounted() {
    this.canvas = new fabric.Canvas(this.editorId);
    this.canvas.setDimensions({
      width: this.canvasWidth,
      height: this.canvasHeight,
    });
    this.canvas.backgroundColor = "#fff";
    this.canvas.selection = false;
    this.loadControls();
    this.setTool();
  },
  methods: {
    resetTool() {
      this.setTool();
    },
    save() {
      this.drag();
      return {
        data: this.canvas.toJSON(),
        file_content: this.canvas.toDataURL({
          format: "jpeg",
          quality: 0.8,
        }),
      };
    },
    restore(data) {
      this.restoring = true;
      this.canvas.loadFromJSON(data, () => {
        this.canvas.renderAll.bind(this.canvas);
        new CanvasHistory(this.canvas.toJSON());
        this.restoring = false;
      });
    },
    showMobileTextHandler(textObject) {
      this.mobileText = textObject.text;
      this.textObject = textObject;
      this.visibleMobileTextHandler = true;
    },
    saveMobileText() {
      if (this.textObject.text != this.mobileText) {
        this.textObject.set({ text: this.mobileText });
        this.textObject.exitEditing();
        this.canvas.setActiveObject(this.textObject);
        this.canvas.requestRenderAll();
        new CanvasHistory(this.canvas);
      }
      this.textObject = null;
      this.mobileText = "";
    },
    cancelMobileText() {
      this.textObject.exitEditing();
      this.canvas.setActiveObject(this.textObject);
      this.canvas.requestRenderAll();
      this.textObject = null;
      this.mobileText = "";
    },
    getObjectsById(objectId) {
      let objects = this.canvas.getObjects();
      let findedObject = [];
      objects.map((object) => {
        if (object.id && object.id == objectId) {
          findedObject.push(object);
        }
      });
      return findedObject;
    },
    setBackgroundImage(imageUrl, backgroundColor = "#fff") {
      this.backgroundImage = imageUrl;
      let inst = this;

      const handleImage = (image) => {
        const center = inst.canvas.getCenter();
        inst.canvas.setBackgroundImage(
          image,
          inst.canvas.renderAll.bind(inst.canvas),
          {
            top: center.top,
            left: center.left,
            originX: "center",
            originY: "center",
          }
        );
        new CanvasHistory(inst.canvas.toJSON());
        inst.canvas.renderAll();
      };

      const file = URL.createObjectURL(imageUrl);
      if (imageUrl.type === "image/svg+xml") {
        fabric.loadSVGFromURL(file, (objects, options) => {
          var image = fabric.util.groupSVGElements(objects, options);
          image.scaleToWidth(inst.canvasWidth);
          image.scaleToHeight(inst.canvasHeight);
          handleImage(image);
        });
      } else {
        fabric.Image.fromURL(file, function (image) {
          image.scaleToWidth(inst.canvasWidth);
          image.scaleToHeight(inst.canvasHeight);

          const data = image.toDataURL({
            format: "jpeg",
            quality: 0.8,
          });

          fabric.Image.fromURL(data, function (img) {
            handleImage(img);
          });
        });
      }
    },
    clear() {
      this.drag();
      const bi = this.canvas.backgroundImage;
      this.canvas.clear();
      this.canvas.backgroundImage = bi;
      this.canvas.setDimensions({
        width: this.canvasWidth,
        height: this.canvasHeight,
      });
      this.canvas.backgroundColor = "#fff";
      this.canvas.selection = false;
      this.canvas.renderAll();
      this.objects = [];
      this.history = [];
      new CanvasHistory(this.canvas.toJSON(), true);
      this.resetTool();
    },
    setTool() {
      switch (this.tool) {
        case "text":
          this.params = {
            fill: this.toolColor,
            fontFamily: "Lato",
            fontSize: this.toolSize,
            fontStyle: "normal",
            placeholder: "Tekst",
            mobileTextHandler: this.$vuetify.breakpoint.smAndDown
              ? this.showMobileTextHandler
              : undefined,
            isMobile: this.$vuetify.breakpoint.smAndDown,
          };
          this.addText(this.params);
          break;
        case "circle":
          this.params = {
            fill: "transparent",
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            strokeUniform: true,
            noScaleCache: false,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
            strokeLineCap: "round",
          };
          this.drawCircle(this.params);
          break;
        case "rect":
          this.params = {
            fill: "transparent",
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            strokeUniform: true,
            noScaleCache: false,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
            strokeLineCap: "round",
          };
          this.drawRectangle(this.params);
          break;
        case "triangle":
          this.params = {
            fill: "transparent",
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            strokeUniform: true,
            noScaleCache: false,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
            strokeLineCap: "round",
          };
          this.drawTriangle(this.params);
          break;
        case "line":
          this.params = {
            fill: "transparent",
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            strokeUniform: true,
            noScaleCache: false,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
            strokeLineCap: "round",
          };
          this.drawLine(this.params);
          break;
        case "curve":
          this.params = {
            fill: "transparent",
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
            strokeLineCap: "round",
          };
          this.drawCurve(this.params);
          break;
        case "selectMode":
          this.canvas.discardActiveObject().renderAll();
          this.drag();
          this.select(this.params);
          break;
        case "arrow":
          this.params = {
            fill: "transparent",
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            strokeUniform: true,
            noScaleCache: false,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
            strokeLineCap: "round",
          };
          this.drawArrow(this.params);
          break;
        case "freeDrawing":
          this.params = {
            stroke: this.toolColor,
            strokeWidth: this.toolStrokeWidth,
            drawingMode: true,
            strokeDashArray: this.toolDashed == "true" ? [5, 10] : false,
          };
          this.draw(this.params);
          break;
        case "scar-overgrowth":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.65,
            scaleFactor: 1.3,
            image: require("@/assets/editor/uro/brushes/overgrowth.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-pain":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.1,
            image: require("@/assets/editor/uro/brushes/pain.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-vanishing":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.1,
            image: require("@/assets/editor/uro/brushes/vanishing.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-scarring":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 0.85,
            image: require("@/assets/editor/uro/brushes/scarring.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-hypersensitivity":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.15,
            image: require("@/assets/editor/uro/brushes/hypersensitivity.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-hyperinsensitivity":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.6,
            scaleFactor: 1.1,
            image: require("@/assets/editor/uro/brushes/hyperinsensitivity.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-hyperpigmentation":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.15,
            image: require("@/assets/editor/uro/brushes/hyperpigmentation.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-reddening":
          this.params = {
            image: require("@/assets/editor/uro/brushes/reddening.svg"),
          };
          this.drawImage(this.params);
          break;
        case "scar-displacement":
          this.params = {
            fill: "transparent",
            stroke: "red",
            strokeWidth: this.toolStrokeWidth / 2.4,
            strokeUniform: true,
            noScaleCache: false,
            strokeLineCap: "round",
          };
          this.drawArrow(this.params);
          break;
        case "scar-bulge":
          this.params = {
            image: require("@/assets/editor/uro/brushes/bulge.svg"),
          };
          this.drawImage(this.params);
          break;
        case "scar-retraction":
          this.params = {
            image: require("@/assets/editor/uro/brushes/retraction.svg"),
          };
          this.drawImage(this.params);
          break;
        case "scar-gluing":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 0.85,
            image: require("@/assets/editor/uro/brushes/gluing.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-adhesion":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 0.8,
            scaleFactor: 1.5,
            image: require("@/assets/editor/uro/brushes/adhesion.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-shine":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1,
            image: require("@/assets/editor/uro/brushes/shine.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-pulling":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1.5,
            scaleFactor: 1.3,
            image: require("@/assets/editor/uro/brushes/pulling.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-itching":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1,
            scaleFactor: 1.8,
            image: require("@/assets/editor/uro/brushes/itching.svg"),
          };
          this.drawScars(this.params);
          break;
        case "scar-scab":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            pattern: require("@/assets/editor/uro/img/scab.png"),
          };
          this.drawPattern(this.params);
          break;
        case "scar-necrosis":
          this.params = {
            stroke: 'black',
            strokeWidth: this.toolStrokeWidth,
            drawingMode: true,
          };
          this.draw(this.params);
          break;
        case "scar-electrodermal":
          this.params = {
            drawingMode: true,
            strokeWidth: this.toolStrokeWidth,
            distanceFactor: 1,
            scaleFactor: 1.8,
            image: require("@/assets/editor/uro/brushes/electrodermal.svg"),
          };
          this.drawScars(this.params);
          break;
        default:
          break;
      }
    },
    saveImage() {
      return this.canvas.toDataURL({
        format: "jpeg",
        quality: 1,
      });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    download() {
      var link = document.createElement("a");
      var imgData = this.saveImage();
      var blob = this.dataURLtoBlob(imgData);
      var objurl = URL.createObjectURL(blob);
      link.href = objurl;
      link.setAttribute("download", "ilustracja.jpg");
      document.body.appendChild(link);
      link.click();
    },
    print() {
      var imgData = this.saveImage();
      var blob = this.dataURLtoBlob(imgData);
      var objurl = URL.createObjectURL(blob);
      printJS(objurl, "image");
    },
    drawCircle(params) {
      this.drag();
      this.createShape = true;
      new Shape(this.canvas, this.createShape, "circle", params);
    },
    drawRectangle(params) {
      this.drag();
      this.createShape = true;
      new Shape(this.canvas, this.createShape, "rect", params);
    },
    drawTriangle(params) {
      this.drag();
      this.createShape = true;
      new Shape(this.canvas, this.createShape, "triangle", params);
    },
    drawLine(params) {
      this.drag();
      this.createShape = true;
      new Shape(this.canvas, this.createShape, "line", params);
    },
    drawImage(params) {
      this.drag();
      this.createShape = true;
      new Shape(this.canvas, this.createShape, "image", params);
    },
    drawCurve(params) {
      if (this.createCurve === false) {
        this.drag();
        console.log("Start drawing curve...");
        this.createCurve = true;
        new Curve(this.canvas, this.createCurve, false, params);
      } else {
        new Curve(this.canvas, this.createCurve, true, params);
      }
    },
    drawArrow(params) {
      this.drag();
      this.createArrow = true;
      new Arrow(this.canvas, this.createArrow, params);
    },
    drag(byPass = false) {
      if (this.tool !== "selectMode" && !byPass) {
        this.canvas.discardActiveObject().renderAll();
      }

      this.canvas.off("selection:created");
      this.canvas.off("selection:updated");

      this.canvas.isDrawingMode = false;
      this.canvas.forEachObject((object) => {
        object.selectable = true;
        object.evented = true;
        object.hasControls = true;
        object.hasBorders = true;
      });

      if (this.createArrow) {
        this.createArrow = false;
        new Arrow(this.canvas, false);
      }
      
      if (this.createShape) {
        this.createShape = false;
        new Shape(this.canvas, false);
      }

      if (this.createText) {
        this.createText = false;
        new Text(this.canvas, false);
      }

      if (this.createCurve) {
        this.createCurve = false;
        new Curve(this.canvas, false, false);
      }

      this.canvas.requestRenderAll();
    },
    addText(params) {
      this.drag(this.createText);
      this.createText = true;
      new Text(this.canvas, this.createText, params);
    },
    async undo() {
      //If drawing curve was active, then remove one joint;
      if (
        new Curve(
          this.canvas,
          this.createCurve,
          true,
          this.params
        ).removeJoint()
      ) {
        return;
      }
      if (this.canvas.getActiveObject()) {
        this.canvas.discardActiveObject().renderAll();
      }
      this.drag();
      this.history = new CanvasHistory();
      if (this.history.length > 1) {
        this.objects.push(this.history.pop());
        const obj = this.history[this.history.length - 1];
        JSON.parse(JSON.stringify(obj));

        this.restoring = true;
        this.canvas.loadFromJSON(obj, () => {
          this.restoring = false;
        });
      }
      this.canvas.requestRenderAll();
      this.resetTool();
    },
    async redo() {
      //If drawing curve was active, then restore one joint;
      if (
        new Curve(
          this.canvas,
          this.createCurve,
          true,
          this.params
        ).restoreJoint()
      ) {
        return;
      }
      this.drag();
      if (this.objects.length > 0) {
        const obj = this.objects.pop();
        JSON.parse(JSON.stringify(obj));

        this.restoring = true;
        this.canvas.loadFromJSON(obj, () => {
          new CanvasHistory(obj);
          this.restoring = false;
        });
      }
      this.canvas.requestRenderAll();
      this.resetTool();
    },
    draw(params) {
      if (this.canvas.__eventListeners) {
        this.canvas.__eventListeners["object:added"] = null;
      }
      this.drag();
      this.canvas.isDrawingMode = params.drawingMode;
      this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
      this.canvas.freeDrawingBrush.color = params.stroke;
      this.canvas.freeDrawingBrush.width = params.strokeWidth;
      this.canvas.freeDrawingBrush.strokeDashArray = params.strokeDashArray;
      this.canvas.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: 0,
        affectStroke: true,
        color: params.stroke,
      });
      let inst = this;
      this.canvas.on("object:added", function () {
        if (inst.canvas.isDrawingMode && !inst.restoring) {
          new CanvasHistory(inst.canvas.toJSON());
        }
      });
      this.canvas.renderAll();
    },
    drawScars(params) {
      if (this.canvas.__eventListeners) {
        this.canvas.__eventListeners["object:added"] = null;
      }
      this.drag();
      this.canvas.isDrawingMode = params.drawingMode;
      this.canvas.freeDrawingBrush = new ScarBrush(this.canvas, params);
      let inst = this;
      this.canvas.on("object:added", function () {
        if (inst.canvas.isDrawingMode && !inst.restoring) {
          new CanvasHistory(inst.canvas.toJSON());
        }
      });
      this.canvas.renderAll();
    },
    drawPattern(params) {
      if (this.canvas.__eventListeners) {
        this.canvas.__eventListeners["object:added"] = null;
      }
      this.drag();
      this.canvas.isDrawingMode = params.drawingMode;

      var img = new Image();
      img.src = params.pattern;
      this.canvas.freeDrawingBrush = new fabric.PatternBrush(this.canvas);
      this.canvas.freeDrawingBrush.source = img;
      this.canvas.freeDrawingBrush.width = params.strokeWidth;
      let inst = this;
      this.canvas.on("object:added", function () {
        if (inst.canvas.isDrawingMode && !inst.restoring) {
          new CanvasHistory(inst.canvas.toJSON());
        }
      });
      this.canvas.renderAll();
    },
    loadControls() {
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = "#1BAAD6";
      fabric.Object.prototype.cornerStyle = "circle";

      const deleteIcon =
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

      function renderIcon(icon) {
        return function renderIcon(
          ctx,
          left,
          top,
          styleOverride,
          fabricObject
        ) {
          var size = this.cornerSize;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(icon, -size / 2, -size / 2, size, size);
          ctx.restore();
        };
      }

      var deleteImg = document.createElement("img");
      deleteImg.src = deleteIcon;

      let inst = this;

      function deleteObject(eventData, transform) {
        let target = transform.target;
        inst.objects.push(target);
        inst.canvas.remove(target);
        inst.canvas.requestRenderAll();
      }

      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -20,
        offsetX: 20,
        cursorStyle: "pointer",
        mouseUpHandler: deleteObject,
        render: renderIcon(deleteImg),
        cornerSize: 28,
      });
    },
    select(params) {
      let outerThis = this;

      const getColorOfSelectedItem = function (o) {
        if (outerThis.canvas.getActiveObject()) {
          if (outerThis.canvas.getActiveObject().name === "handler") {
            return;
          }

          if (outerThis.canvas.getActiveObject().text) {
            outerThis.$emit("color", outerThis.canvas.getActiveObject().fill);
          } else {
            outerThis.$emit("color", outerThis.canvas.getActiveObject().stroke);
          }
        } else {
          outerThis.canvas.setActiveObject(o.target);
        }
        outerThis.canvas.requestRenderAll();
      };
      this.canvas.on("selection:created", getColorOfSelectedItem);
      this.canvas.on("selection:updated", getColorOfSelectedItem);

      if (this.canvas.getActiveObject()) {
        if (
          this.canvas.getActiveObject().text &&
          this.canvas.getActiveObject().fill != params.stroke
        ) {
          this.canvas
            .getActiveObject()
            .set({ fill: params.stroke, stroke: params.stroke });
          new CanvasHistory(this.canvas.toJSON());
        } else if (
          this.canvas.getActiveObject().fill &&
          typeof this.canvas.getActiveObject().fill === "object" &&
          this.canvas.getActiveObject().stroke != params.stroke
        ) {
          console.log(new Shape(this.canvas, false));
          this.canvas.getActiveObject().set({ stroke: params.stroke });
          new Shape(this.canvas, false).prototype.setFill(
            this.canvas.getActiveObject(),
            params.stroke
          );
        } else if (this.canvas.getActiveObject().stroke != params.stroke) {
          this.canvas.getActiveObject().set({ stroke: params.stroke });
          new CanvasHistory(this.canvas.toJSON());
        }
        this.canvas.requestRenderAll();
      }
    },
  },
};
</script>

<style>
.upper-canvas {
  z-index: 1;
}
</style>
