import {fabric} from "fabric";

export default (function () {

    let history = [];
    function CanvasHistory(canvasJson, clear=false) {
        if (clear) {
            history = [];
            return history;
        }

        if(canvasJson){  
            canvasJson.objects.forEach(function(object) {
                if(object.type == "lineArrow"){
                    object.heads = [1, 0];
                }
            });
            history.push(canvasJson);  
        }
        return history;    
    }; 
    return CanvasHistory;
}());
