import { fabric } from 'fabric';
import CanvasHistory from "./canvasHistory";

export default (function () {
    let drag;
    let shape;
    let color;
    let lineWidth;
    let fillCircle;
    let strokeDashArray;
    let properties;
    let isDown = false;
    let image;

    function Shape(canvas, draggable = false, type, params) {
        if (!draggable) {
            drag = false;
            return Shape;
        }
        if (color && color !== params.stroke) {
            color = params.stroke;
            shape = type;
            new Shape(canvas, true, shape, params)
            return Shape;
        }
        if ((shape && shape !== type)) {
            shape = type;
            drag = true;
            new Shape(canvas, true, shape, params)
            return Shape;
        }
        properties = params;
        if (properties) {
            fillCircle = properties.fill;
            color = properties.stroke;
            lineWidth = properties.strokeWidth;
            strokeDashArray = properties.strokeDashArray;
            image = properties.image
        }
        this.canvas = canvas;
        this.className = 'Shape';
        this.isDrawing = false;
        this.origX = 0;
        this.origY = 0;

        drag = draggable;
        shape = type;

        this.bindEvents();
    }

    Shape.prototype.bindEvents = function () {
        let inst = this;
        document.onkeydown = (e) => {
            if (e.which === 46 || e.keycode === 46) {
                inst.canvas.getActiveObjects().forEach((obj) => {
                    inst.canvas.remove(obj)
                });
            }
            inst.canvas.renderAll()
        };
        inst.selectable = true;
        inst.canvas.off('mouse:down');
        inst.canvas.on('mouse:down', function (o) {
            inst.onMouseDown(o);
        });

        inst.canvas.off('mouse:move');
        inst.canvas.on('mouse:move', function (o) {
            inst.onMouseMove(o);
        });

        inst.canvas.off('mouse:up');
        inst.canvas.on('mouse:up', function (o) {
            inst.onMouseUp(o);
        });

        inst.canvas.off('object:moving');
        inst.canvas.on('object:moving', function () {
            inst.disable();
        });
    };

    Shape.prototype.onMouseUp = function () {
        isDown = false;
        let inst = this;

        if (!inst.isEnable()) {
            return;
        }

        if (drag) {
            inst.canvas.getObjects().forEach(function (object, index, array) {
                if (index === (array.length - 1)) {
                    if (inst.canvas.getActiveObject() && inst.canvas.getActiveObject()._objects && inst.canvas.getActiveObject()._objects.length > 1) {
                        inst.canvas.setActiveObject(object);
                    }
                }
            });
            if (inst.canvas.getActiveObject()) {
                inst.canvas.getActiveObject().hasControls = false;
                inst.canvas.getActiveObject().hasBorders = false;
                inst.canvas.getActiveObject().lockMovementX = true;
                inst.canvas.getActiveObject().lockMovementY = true;
                inst.canvas.getActiveObject().lockUniScaling = true;
            }
            inst.canvas.renderAll();
        }

        new CanvasHistory(inst.canvas.toJSON());
        inst.disable();
    };

    Shape.prototype.onMouseMove = function (o) {
        let inst = this;
        if (!inst.isEnable()) {
            return;
        }
        inst.canvas.selection = false;
        let pointer = inst.canvas.getPointer(o.e);
        let activeObj = inst.canvas.getActiveObject();
        if (!activeObj) {
            return;
        }

        activeObj.noScaleCache = false;
        activeObj.strokeUniform = true;

        if (shape !== "image") {
            activeObj.stroke = color;
            activeObj.strokeWidth = lineWidth;
        }

        if (shape !== "image") {
            if (this.origX > pointer.x) {
                activeObj.set({
                    left: Math.abs(pointer.x)
                });
            }
            if (this.origY > pointer.y) {
                activeObj.set({
                    top: Math.abs(pointer.y)
                });
            }
        }
        if (shape == "rect" || shape == "triangle") {
            activeObj.set({
                width: Math.abs(this.origX - pointer.x),
                height: Math.abs(this.origY - pointer.y),
            });
        }

        if (shape == "line") {
            if (!isDown) return;
            activeObj.set({ x2: pointer.x, y2: pointer.y });
        }

        if (shape == "circle") {
            activeObj.set({
                rx: Math.abs(this.origX - pointer.x) / 2,
                ry: Math.abs(this.origY - pointer.y) / 2
            });
        }

        if (shape == "image") {
            const w = Math.abs(this.origX - pointer.x);
            const h = Math.abs(this.origY - pointer.y)
            activeObj.scaleToWidth(w);
            activeObj.scaleToHeight(h);
        }

        activeObj.setCoords();
        inst.canvas.renderAll();
    };

    Shape.prototype.onMouseDown = function (o) {
        isDown = true;
        let inst = this;
        if (!drag) {
            if (inst.canvas.getActiveObject()) {
                inst.canvas.getActiveObject().hasControls = true;
                inst.canvas.getActiveObject().hasBorders = true;
                inst.canvas.getActiveObject().lockMovementX = false;
                inst.canvas.getActiveObject().lockMovementY = false;
                inst.canvas.getActiveObject().lockUniScaling = false;
                inst.canvas.renderAll();
            }
            inst.disable();
            return;
        }
        inst.enable();

        // if (inst.canvas.getActiveObject()) {
        //     inst.canvas.getActiveObject().hasControls = false;
        //     inst.canvas.getActiveObject().hasBorders = false;
        //     inst.canvas.getActiveObject().lockMovementX = true;
        //     inst.canvas.getActiveObject().lockMovementY = true;
        //     inst.canvas.getActiveObject().lockUniScaling = true;
        //     inst.canvas.renderAll();
        // }

        let pointer = inst.canvas.getPointer(o.e);
        this.origX = pointer.x;
        this.origY = pointer.y;

        if (shape === "rect") {
            let rect = new fabric.Rect({
                left: this.origX,
                top: this.origY,
                originX: 'left',
                originY: 'top',
                fill: fillCircle,
                width: pointer.x - this.origX,
                height: pointer.y - this.origY,
                stroke: color,
                strokeWidth: lineWidth,
                strokeDashArray: strokeDashArray,
                strokeLineCap: 'round',
                hasControls: false
            });
            
            if (strokeDashArray !== false) {
                inst.setFill(rect);
            }

            inst.canvas.add(rect).setActiveObject(rect);
        }

        if (shape === "circle") {
            let circle = new fabric.Ellipse({
                top: this.origY,
                left: this.origX,
                rx: 0,
                ry: 0,
                fill: fillCircle,
                stroke: color,
                strokeWidth: lineWidth,
                strokeDashArray: strokeDashArray,
                strokeLineCap: 'round',
                hasControls: false
            });

            if (strokeDashArray !== false) {
                inst.setFill(circle);
            }

            inst.canvas.add(circle).setActiveObject(circle);
        }

        if (shape === "triangle") {
            let triangle = new fabric.Triangle({
                left: this.origX,
                top: this.origY,
                originX: 'left',
                originY: 'top',
                fill: fillCircle,
                width: pointer.x - this.origX,
                height: pointer.y - this.origY,
                stroke: color,
                strokeWidth: lineWidth,
                strokeDashArray: strokeDashArray,
                strokeLineCap: 'round',
                hasControls: false
            });

            if (strokeDashArray !== false) {
                inst.setFill(triangle);
            }

            inst.canvas.add(triangle).setActiveObject(triangle);
        }

        if (shape === "line") {
            var points = [pointer.x, pointer.y, pointer.x, pointer.y];
            let line = new fabric.Line(points, {
                strokeDashArray: strokeDashArray,
                strokeLineCap: 'round',
                stroke: fillCircle,
                originX: 'center',
                originY: 'center',
                hasControls: false
            });
            inst.canvas.add(line).setActiveObject(line);
        }

        if (shape === "image") {
            fabric.loadSVGFromURL(image, function(objects, options) {
                let obj = fabric.util.groupSVGElements(objects, options);
                obj.set({
                    left: inst.origX,
                    top: inst.origY,
                    hasControls: false
                })
                inst.canvas.add(obj).setActiveObject(obj);
            });
        }
    };

    Shape.prototype.setFill = function (shape, customColor=false) {
        let col = customColor
        if (col === false) {
            col = color;
        }
        
        const svg = "data:image/svg+xml;base64," + btoa(`
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'>
        <rect width='10' height='10' fill='transparent'/>
        <path d='M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2' stroke="${col}" stroke-width="3"/>
        </svg>`);

        new Promise((resolve, reject) => {
            fabric.loadSVGFromURL(svg, function(objects, options) {
                const image = fabric.util.groupSVGElements(objects, options);
                var patternSourceCanvas = new fabric.StaticCanvas();
                patternSourceCanvas.add(image);
                patternSourceCanvas.setDimensions({
                    width: 10,
                    height: 10
                });
                shape.set({fill: new fabric.Pattern({
                        source: patternSourceCanvas.getElement(),
                        repeat: 'repeat'
                    })
                });
                shape.dirty = true;
                shape.evented = true;

                //Bug in fabric.js:
                setTimeout(function() {
                    resolve(customColor);
                }, 100);
            });
        }).then(() => {
            shape.canvas.renderAll();
            if (customColor) {
                new CanvasHistory(shape.canvas.toJSON());
            }
        })
    }

    Shape.prototype.isEnable = function () {
        return this.isDrawing;
    }

    Shape.prototype.enable = function () {
        this.isDrawing = true;
    }

    Shape.prototype.disable = function () {
        this.isDrawing = false;
    }
    return Shape;
}());
