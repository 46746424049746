<template>
  <div>
    <v-menu
      offset-y
      max-width="90"
      :disabled="readonly"
      v-model="visible"
      content-class="zg-select-border-radius"
    >
      <template v-slot:activator="{ on }">
        <v-layout :class="horizontal ? 'align-center' : 'column'">
          <div
            v-if="label"
            :class="[
              'text-subtitle-1 black--text',
              horizontal ? 'mt-1 ml-4' : 'mb-1 ml-4',
            ]"
          >
            {{ label }}
            <span v-if="required" class="text-subtitle-1 primary--text">*</span>
          </div>
          <DefaultButton
            :color="readonly ? 'grey' : 'black'"
            style="min-width: 90px;"
            :class="['select', readonly ? 'avoid-clicks' : '']"
            v-on="on"
            small
          >
            <v-layout justify-space-between mx-1>
              <div
                :style="{
                  backgroundColor: selected,
                  width: '20px',
                  height: '20px',
                }"
              ></div>
              <v-icon small>{{
                visible ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-layout>
          </DefaultButton>
        </v-layout>
      </template>
      <v-list dense>
        <v-list-item v-for="(item, index) in colors" :key="index">
          <v-layout justify-space-between>
            <template v-for="color in item">
              <v-hover :key="color">
                <div
                  slot-scope="{ hover }"
                  @click.prevent="selectColor(color)"
                  :class="hover ? 'elevation-4' : 'elevation-0'"
                  :style="{
                    backgroundColor: color,
                    width: '20px',
                    height: '20px',
                  }"
                ></div>
              </v-hover>
            </template>
          </v-layout>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      default: () => [
        ["#bdc3c7", "#7f8c8d"],
        ["#e74c3c", "#e67e22"],
        ["#f1c40f", "#34495e"],
        ["#9b59b6", "#3498db"],
        ["#2ecc71", "#1abc9c"],
      ],
    },
    label: {
      default: undefined,
    },
    horizontal: {
      default: false,
    },
    required: {
      default: false,
    },
    value: {
      default: 0,
    },
    valueMap: {
      type: Object,
      default() {
        return {
          "#bdc3c7": 0,
          "#7f8c8d": 1,
          "#e74c3c": 2,
          "#e67e22": 3,
          "#f1c40f": 4,
          "#34495e": 5,
          "#9b59b6": 6,
          "#3498db": 7,
          "#2ecc71": 8,
          "#1abc9c": 9,
        };
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  computed: {
    selected: {
      get() {
        return Object.keys(this.valueMap)[this.value | 0];
      },
      set(color) {
        this.$emit("update:value", this.valueMap[color]);
        this.$emit("color", color);
      },
    },
  },
  methods: {
    selectColor(value) {
      this.selected = value;
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  background-color: white;
}
.avoid-clicks {
  pointer-events: none;
}
</style>