<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  watch: {
    tool() {
      this.resetTool();
    },
    toolColor() {
      this.resetTool();
    },
    toolSize() {
      this.resetTool();
    },
    toolStrokeWidth() {
      this.resetTool();
    },
    toolDashed() {
      this.resetTool();
    },
  },
  computed: {
    ...mapState("editor", [
      "tool",
      "toolType",
      "toolColor",
      "toolSize",
      "toolStrokeWidth",
      "toolCategory",
      "toolSubcategory",
      "toolDashed",
    ]),
  },
  methods: {
    ...mapActions("editor", [
      "changeTool",
      "changeToolType",
      "changeToolColor",
      "changeToolSize",
      "changeToolStrokeWidth",
      "changeToolCategory",
      "changeToolSubcategory",
      "changeToolDashed",
    ]),
    resetTool() {},
    checkIfToolCategoryExists() {
      let found = false;
      for (let index = 0; index < this.buttons.length; index++) {
        const button = this.buttons[index];
        if (button.name == this.toolCategory) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.changeToolCategory("selectMode");
        this.changeTool("selectMode");
      }
    },
  },
};
</script>